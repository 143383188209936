import {inject} from '@angular/core';
import {CanMatchFn} from '@angular/router';
import {SpaceSettingsService} from '@q9elements/ui-kit/common';

import {ROUTES_PATH} from '../models/routes-path.enum';
import {NavigationService} from '../services/navigation.service';

export const MetadataDictionariesGuard: CanMatchFn = route => {
  const spaceSettingsService = inject(SpaceSettingsService);

  if (route.path !== ROUTES_PATH.METADATA_DICTIONARIES) {
    spaceSettingsService.updateSettingsByKey('metadataDictionariesPath', route.path!);

    return true;
  }

  const module = spaceSettingsService.getSettingsByKey(
    'metadataDictionariesPath',
    ROUTES_PATH.SF_ORGS
  ) as string;
  const navigationService = inject(NavigationService);

  return navigationService.navigateToModule(module);
};

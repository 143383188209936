import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn} from '@angular/router';
import {CustomField, ChangesType} from '@q9elements/ui-kit/common';

import {CustomFieldsService} from '../services/custom-fields.service';

export const customFieldsResolver: ResolveFn<CustomField[]> = (route: ActivatedRouteSnapshot) => {
  const type = String(route.url[0]) as ChangesType;

  return inject(CustomFieldsService).getCustomFields(type);
};

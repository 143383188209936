import {
  ActivatedRouteSnapshot,
  BaseRouteReuseStrategy,
  createUrlTreeFromSnapshot,
  RouteReuseStrategy
} from '@angular/router';

import {ROUTES_PATH} from '../models/routes-path.enum';

export class AppRouteReuseStrategy extends BaseRouteReuseStrategy {
  override shouldReuseRoute(future: ActivatedRouteSnapshot, curr: ActivatedRouteSnapshot): boolean {
    const shouldReuseRoute = super.shouldReuseRoute(future, curr);
    const futureSegments = getSegmentsFromSnapshot(future);

    if (futureSegments.length <= 1) {
      return shouldReuseRoute;
    }

    // Segments are array of strings, e.g. ['t', '1', 'teams']
    const currentSegments = getSegmentsFromSnapshot(curr);
    const isSameSpaceIndex = futureSegments[1] === currentSegments[1];

    if (isSameSpaceIndex) {
      return shouldReuseRoute;
    }

    const isSpacesModule = [futureSegments[2], currentSegments[2]].every(
      segment => segment === ROUTES_PATH.SPACES
    );

    if (isSpacesModule) {
      return shouldReuseRoute;
    }

    // We should not reuse route if we are switching between spaces
    return false;
  }
}

function getSegmentsFromSnapshot(snapshot: ActivatedRouteSnapshot) {
  return createUrlTreeFromSnapshot(snapshot, []).toString().split('/').filter(Boolean);
}

export function provideRouteReuseStrategy() {
  return {
    provide: RouteReuseStrategy,
    useClass: AppRouteReuseStrategy
  };
}

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CanActivateFn, Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
  ConfigService,
  DialogWidth,
  handleError,
  SessionService,
  SnackBarService,
  ThemePalette
} from '@q9elements/ui-kit/common';
import {get} from 'lodash';
import {catchError, finalize, map, switchMap} from 'rxjs';

import {SfConnectionInfoDialogComponent} from '../dialogs/sf-connection-info/sf-connection-info-dialog.component';
import {RefreshSfTokenResponse, SfCallbackParams} from '../models/salesforce-orgs.interface';
import {sfCallbackParser} from '../utils/sf-callback-parser';

export const SalesforceRefreshCallbackGuard: CanActivateFn = route => {
  const translateService = inject(TranslateService);
  const router = inject(Router);
  const http = inject(HttpClient);
  const snackBarService = inject(SnackBarService);
  const dialog = inject(MatDialog);
  const sessionService = inject(SessionService);
  const parsedSfQueryParams = sfCallbackParser(route.queryParams as SfCallbackParams);
  const {teamId, teamIndex, ...payload} = parsedSfQueryParams;
  const headers = new HttpHeaders({'el-team-id': String(teamId), 'el-team': String(teamIndex)});
  const url = `${ConfigService.environment.API_ENDPOINT}/organizations/${teamId}/salesforce/token`;
  const returnBackToReferenceModels = (
    queryParams:
      | {
          orgId: string;
          message: string;
        }
      | object = {}
  ) =>
    router.navigate([`/t/${+parsedSfQueryParams.teamIndex}/referencemodels-external`], {
      queryParams
    });

  const openSfConnectionInfoDialog = (data: RefreshSfTokenResponse) => {
    return dialog
      .open(SfConnectionInfoDialogComponent, {
        width: DialogWidth.MD,
        disableClose: !data.isReconnect,
        data: {...data, color: ThemePalette.PRIMARY}
      })
      .afterClosed()
      .pipe(finalize(() => sessionService.setLoading(false)));
  };

  sessionService.setLoading();

  return http.post<RefreshSfTokenResponse>(url, payload, {headers}).pipe(
    handleError(),
    map(response => {
      const params: {orgId?: string; message?: string} = {};

      if (response.orgReconnected) {
        params.message = translateService.instant('SF_CONNECTIONS.SANDBOX_RECONNECTED');
      }

      if (response.tokenUpdated) {
        params.message = translateService.instant('SF_CONNECTIONS.TOKEN_REFRESHED');
      }

      if (!response.alreadyConnected) {
        params.orgId = response.orgId;
      }

      return {response, params};
    }),
    switchMap(({response, params}) => {
      if (!response.alreadyConnected) {
        return returnBackToReferenceModels(params);
      }

      return openSfConnectionInfoDialog(response).pipe(
        switchMap(() => returnBackToReferenceModels(params))
      );
    }),
    finalize(() => sessionService.setLoading(false)),
    catchError(err => {
      snackBarService.open(get(err, 'error', err));

      return returnBackToReferenceModels();
    })
  );
};

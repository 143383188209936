import {Routes, UrlMatchResult, UrlSegment} from '@angular/router';

import {AccountVerifyGuard} from '../guards/account-verify.guard';
import {ROUTES_PATH} from '../models/routes-path.enum';

export const routes: Routes = [
  {
    path: ROUTES_PATH.SIGN_IN,
    loadComponent: () =>
      import(`../../authentication/components/login/login.component`).then(c => c.LoginComponent),
    data: {
      showAd: true
    }
  },
  {
    path: ROUTES_PATH.SIGN_UP,
    loadComponent: () =>
      import('../../authentication/components/registration/registration.component').then(
        c => c.RegistrationComponent
      ),
    data: {
      showAd: true
    }
  },
  {
    path: ROUTES_PATH.SIGN_UP_SUCCESS,
    canActivate: [AccountVerifyGuard],
    loadComponent: () =>
      import(
        '../../authentication/components/registration-success/registration-success.component'
      ).then(c => c.RegistrationSuccessComponent)
  },
  {
    path: ROUTES_PATH.FORGOT_PASS,
    loadComponent: () =>
      import('../../authentication/components/forgot-password/forgot-password.component').then(
        c => c.ForgotPasswordComponent
      )
  },
  {
    path: ROUTES_PATH.NEW_PASS,
    children: [
      {
        path: '',
        redirectTo: `/${ROUTES_PATH.SIGN_IN}`,
        pathMatch: 'full'
      },
      {
        path: ':token',
        loadComponent: () =>
          import('../../authentication/components/new-password/new-password.component').then(
            c => c.NewPasswordComponent
          )
      }
    ]
  },
  {
    path: ROUTES_PATH.AUTH,
    children: [
      {
        path: 'callback',
        loadComponent: () =>
          import('../../authentication/components/social-login/social-login.component').then(
            c => c.SocialLoginComponent
          )
      }
    ]
  },
  {
    path: ROUTES_PATH.ACCOUNT_ACCEPT,
    canActivateChild: [AccountVerifyGuard],
    children: [
      {
        path: '',
        loadComponent: () =>
          import('../../authentication/components/account-accept/account-accept.component').then(
            c => c.AccountAcceptComponent
          )
      },
      {
        path: ':token',
        children: []
      }
    ],
    data: {
      showAd: true
    }
  },
  {
    path: ROUTES_PATH.ACCOUNT,
    children: [
      {
        path: 'email-change/:token',
        loadComponent: () =>
          import('../../authentication/components/email-change/email-change.component').then(
            c => c.EmailChangeComponent
          )
      }
    ]
  }
  // {
  //   path: 'salesforce-login/:token'
  // }
];

const paths = routes.map(r => r.path);

export function AuthenticationRoutesMatcher(url: UrlSegment[]): UrlMatchResult | null {
  if (url.length && paths.includes(url[0].path)) {
    return {consumed: []};
  }

  return null;
}

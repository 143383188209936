import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, UrlTree} from '@angular/router';

import {REDIRECT} from '../models/auth';
import {AuthenticationRoutesMatcher} from '../routing/auth.routes';
import {CommonRoutingMatcher} from '../routing/common.routes';
import {Q9maCookieService} from '../services/cookie.service';

export function RedirectGuard(route: ActivatedRouteSnapshot): boolean | UrlTree {
  const matcher = route.routeConfig?.matcher;
  const isAuthenticationRoute = matcher === AuthenticationRoutesMatcher;
  let redirect = route.queryParamMap.get(REDIRECT);
  const cookieService = inject(Q9maCookieService);

  // If route is authentication route and redirect query param is present,
  // update redirect param in cookies and let it pass through
  if (isAuthenticationRoute && redirect) {
    cookieService.updateRedirect(redirect);

    return true;
  }

  const isSpaceRoute = matcher === CommonRoutingMatcher;

  redirect = cookieService.getRedirect();

  // If route is space route and redirect param is present in cookies,
  // delete redirect param and redirect to the saved url
  if (isSpaceRoute && redirect) {
    cookieService.deleteRedirect();

    window.location.href = redirect;

    return false;
  }

  return true;
}

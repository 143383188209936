import {NgIf} from '@angular/common';
import {Component, inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';
import {
  DialogCloseButtonComponent,
  PrimaryButtonComponent,
  SecondaryButtonComponent,
  SectionComponent,
  TextFieldComponent
} from '@q9elements/ui-kit/components';

import {SfConnectionInfoDialogData} from '../../models/salesforce-orgs.interface';

@Component({
  standalone: true,
  selector: 'q9ma-sf-connection-info-dialog',
  imports: [
    TranslateModule,
    DialogCloseButtonComponent,
    MatDialogModule,
    PrimaryButtonComponent,
    SecondaryButtonComponent,
    SectionComponent,
    TextFieldComponent,
    NgIf
  ],
  templateUrl: './sf-connection-info-dialog.component.html'
})
export class SfConnectionInfoDialogComponent {
  public data = inject<SfConnectionInfoDialogData>(MAT_DIALOG_DATA);
}

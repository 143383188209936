import {Injectable} from '@angular/core';
import {BaseDaoService, UserEnterprisePermissions} from '@q9elements/ui-kit/common';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class AuthDaoService extends BaseDaoService {
  fetchUserEnterprisePermissions(): Observable<UserEnterprisePermissions> {
    return this.http.get<UserEnterprisePermissions>(
      `${this.apiUrl}/account/enterprise/permissions`
    );
  }
}

import {inject} from '@angular/core';
import {CanMatchFn, Router} from '@angular/router';
import {SessionService} from '@q9elements/ui-kit/common';
import {map} from 'rxjs';

import {ROUTES_PATH} from '../models/routes-path.enum';
import {Q9maCookieService} from '../services/cookie.service';

export const ActiveUserGuard: CanMatchFn = () => {
  const cookieService = inject(Q9maCookieService);
  const router = inject(Router);
  const isTokenValid = cookieService.isTokenValid();
  const sessionService = inject(SessionService);
  const newPassPathSegment = getNewPassPathSegment();

  if (newPassPathSegment.includes(ROUTES_PATH.NEW_PASS) && isTokenValid) {
    cookieService.deleteToken();

    return true;
  }

  if (isTokenValid) {
    return sessionService.startSession().pipe(
      map(() => {
        const user = sessionService.user()!;

        return user?.state === 'active' ? router.createUrlTree(['t']) : true;
      })
    );
  }

  return true;
};

function getNewPassPathSegment() {
  const segments = window.location.pathname.split('/');

  return segments[1];
}

import {inject} from '@angular/core';
import {ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot} from '@angular/router';
import {UserEnterprisePermissions} from '@q9elements/ui-kit/common';
import {Observable} from 'rxjs';

import {AuthDaoService} from '../services/auth-dao.service';

export const UserEnterprisePermissionsResolver: ResolveFn<UserEnterprisePermissions> = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
  authDaoService: AuthDaoService = inject(AuthDaoService)
): Observable<UserEnterprisePermissions> => authDaoService.fetchUserEnterprisePermissions();

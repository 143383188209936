import {AfterViewInit, ChangeDetectionStrategy, Component} from '@angular/core';
import {ConfigService, GainSightService, SessionService} from '@q9elements/ui-kit/common';

@Component({
  selector: 'q9ma-main-app',
  template: `
    <q9-page-loader [isLoading]="isLoading()"></q9-page-loader>
    <q9-loading-placeholder [isLoading]="isLoading()"></q9-loading-placeholder>

    <div class="q9ma-main-app">
      <router-outlet></router-outlet>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements AfterViewInit {
  readonly isLoading = this.sessionService.isLoading;

  constructor(
    private sessionService: SessionService,
    private gainSightService: GainSightService
  ) {}

  ngAfterViewInit() {
    // @ts-ignore
    this.gainSightService.loadScript(ConfigService.environment.PX_KEY);
  }
}

import {NgIf} from '@angular/common';
import {Component, OnInit, signal, ViewEncapsulation} from '@angular/core';
import {MatCardModule} from '@angular/material/card';
import {ActivatedRoute, NavigationEnd, Router, RouterOutlet} from '@angular/router';
import {TakeUntilComponentDestroy} from '@q9elements/ui-kit/common';
import {get} from 'lodash';
import {filter} from 'rxjs';

@Component({
  selector: 'q9ma-auth-wrapper',
  standalone: true,
  templateUrl: './auth-wrapper.component.html',
  imports: [RouterOutlet, NgIf, MatCardModule],
  styleUrls: ['./auth-wrapper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AuthWrapperComponent extends TakeUntilComponentDestroy implements OnInit {
  showAd = signal(true);

  constructor(
    private activatedRoute: ActivatedRoute,
    private route: Router
  ) {
    super();
  }

  ngOnInit() {
    this.updateShowAd();

    this.route.events
      .pipe(
        this.takeUntilComponentDestroy(),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(() => this.updateShowAd());
  }

  private updateShowAd() {
    const showAd = get(this.activatedRoute, 'snapshot.firstChild.data.showAd', false);

    this.showAd.set(showAd);
  }
}

import {inject} from '@angular/core';
import {CanActivateFn, Router} from '@angular/router';
import {SessionService} from '@q9elements/ui-kit/common';

import {USER_STATE} from '../models/user-state.enum';
import {Q9maCookieService} from '../services/cookie.service';

export const LogoutGuard: CanActivateFn = route => {
  const router = inject(Router);

  if (route.queryParamMap.has('logout')) {
    logout();
  }

  const user = inject(SessionService).user();

  if (user?.state === USER_STATE.NOT_VERIFIED) {
    return router.createUrlTree(['signup-success']);
  }

  return router.createUrlTree(['signin']);
};

function logout() {
  inject(SessionService).cleanupUser();
  inject(Q9maCookieService).deleteToken();
  // TODO: unsubscribe from all notifications, firebases, etc.
}

import {inject} from '@angular/core';
import {CanMatchFn, Router, UrlTree} from '@angular/router';
import {SessionService} from '@q9elements/ui-kit/common';

import {USER_STATE} from '../models/user-state.enum';
import {AuthenticationRoutesMatcher} from '../routing/auth.routes';
import {CommonRoutingMatcher} from '../routing/common.routes';
import {Q9maCookieService} from '../services/cookie.service';

export const AuthGuard: CanMatchFn = (route): boolean | UrlTree => {
  const isTokenValid = inject(Q9maCookieService).isTokenValid();
  const isAuthenticationRoute = route.matcher === AuthenticationRoutesMatcher;
  const isUserActive = inject(SessionService).user()?.state === USER_STATE.ACTIVE;

  // If token is present and route is authentication route, redirect to dashboard
  if (isTokenValid && isAuthenticationRoute && isUserActive) {
    return redirectTo('t');
  }

  // If token is present and route is not authentication route, let it pass through
  // Otherwise, cleanup user and redirect to sign in page
  if (isTokenValid) {
    return true;
  } else {
    inject(SessionService).cleanupUser();
  }

  const isSpaceRoute = route.matcher === CommonRoutingMatcher;

  // If token is not present and route is space route, redirect to sign in page
  if (isSpaceRoute) {
    return redirectTo('signin', {
      redirect: encodeURIComponent(window.location.href)
    });
  }

  // If token is not present and route is not space route, let it pass through
  return true;
};

function redirectTo(path: string, queryParams?: {redirect: string}) {
  const router = inject(Router);

  return router.createUrlTree([path], {
    queryParams,
    relativeTo: null
  });
}

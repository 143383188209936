import {Routes, UrlMatchResult, UrlSegment} from '@angular/router';

import {ChangesGuard} from '../guards/changes.guard';
import {MetadataDictionariesGuard} from '../guards/metadata-dictionaries.guard';
import {ROUTES_PATH} from '../models/routes-path.enum';
import {customFieldsResolver} from '../resolvers/custom-fields.resolver';

export const routes: Routes = [
  {
    path: ROUTES_PATH.SPACES,
    title: 'Spaces',
    loadChildren: () => import('../../spaces/spaces.module').then(m => m.SpacesModule)
  },
  {
    path: ROUTES_PATH.RELEASES,
    title: 'Releases',
    loadChildren: () => import('../../releases/releases.module').then(m => m.ReleasesModule)
  },
  {
    path: ROUTES_PATH.URL_LIBRARY,
    title: 'Url Library',
    loadChildren: () => import('../../url-library/url-library.module').then(m => m.UrlLibraryModule)
  },
  {
    path: ROUTES_PATH.METADATA_DICTIONARIES,
    title: 'Metadata dictionaries',
    children: [],
    canMatch: [MetadataDictionariesGuard],
    pathMatch: 'full'
  },
  {
    path: ROUTES_PATH.REFERENCE_MODEL,
    title: 'Internal Models',
    canMatch: [MetadataDictionariesGuard],
    loadChildren: () =>
      import('../../reference-models/reference-models.module').then(m => m.ReferenceModelsModule)
  },
  {
    path: ROUTES_PATH.SF_ORGS,
    title: 'External Orgs',
    canMatch: [MetadataDictionariesGuard],
    loadChildren: () =>
      import('../../salesforce-orgs/salesforce-orgs.module').then(m => m.SalesforceOrgsModule)
  },
  {
    path: ROUTES_PATH.DIAGRAMS,
    title: 'Diagrams',
    loadChildren: () => import('../../maps/maps.module').then(m => m.MapsModule)
  },
  {
    path: ROUTES_PATH.STORIES,
    title: 'Stories',
    resolve: {
      customFields: customFieldsResolver
    },
    canMatch: [ChangesGuard],
    loadChildren: () => import('../../stories/stories.module').then(m => m.StoriesModule)
  },
  {
    path: ROUTES_PATH.REQUIREMENTS,
    title: 'Requirements',
    resolve: {
      customFields: customFieldsResolver
    },
    canMatch: [ChangesGuard],
    loadChildren: () =>
      import('../../requirements/requirements.module').then(m => m.RequirementsModule)
  },
  {
    path: ROUTES_PATH.FEEDBACKS,
    title: 'Feedbacks',
    canMatch: [ChangesGuard],
    loadChildren: () => import('../../feedbacks/feedbacks.module').then(m => m.FeedbacksModule)
  },
  {
    path: ROUTES_PATH.PROFILE,
    title: 'Profile',
    loadChildren: () => import('../../profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: ROUTES_PATH.CHANGES,
    title: 'Changes',
    children: [],
    canMatch: [ChangesGuard],
    pathMatch: 'full'
  },
  {
    path: ROUTES_PATH.DASHBOARD,
    title: 'Dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: '**',
    redirectTo: ROUTES_PATH.DASHBOARD
  }
];

export function CommonRoutingMatcher(segments: UrlSegment[]): UrlMatchResult | null {
  if (!segments.length) {
    return null;
  }

  const [root] = segments;

  if (['t', ROUTES_PATH.TASKS].includes(root?.path)) {
    return {consumed: []};
  }

  return null;
}

import {HttpClient, HttpHeaders} from '@angular/common/http';
import {inject} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {CanActivateFn, Router} from '@angular/router';
import {
  ConfigService,
  DialogWidth,
  handleError,
  SessionService,
  ThemePalette
} from '@q9elements/ui-kit/common';
import {ConfirmModalDialogComponent} from '@q9elements/ui-kit/components';
import {addHours, getHours, setHours, setMinutes, setSeconds} from 'date-fns';
import {get} from 'lodash';
import {catchError, finalize, map, mergeMap, of, switchMap, tap} from 'rxjs';

import {SfConnectionInfoDialogComponent} from '../dialogs/sf-connection-info/sf-connection-info-dialog.component';
import {
  ConnectOrgResponse,
  GeneralOrgInfoResponse,
  OrgGeneralInfoPayload,
  SfCallbackParams
} from '../models/salesforce-orgs.interface';
import {sfCallbackParser} from '../utils/sf-callback-parser';

export const SalesforceConnectCallbackGuard: CanActivateFn = route => {
  const router = inject(Router);
  const http = inject(HttpClient);
  const dialog = inject(MatDialog);
  const sessionService = inject(SessionService);
  const parsedSfQueryParams: SfCallbackParams = sfCallbackParser(
    route.queryParams as SfCallbackParams
  );
  const {
    teamId,
    implementationId,
    isSandbox,
    code,
    reconnectOrg,
    teamIndex,
    type = 'external'
  } = parsedSfQueryParams;

  parsedSfQueryParams['oldOrgId'] = parsedSfQueryParams.orgId;
  const isReconnect = reconnectOrg && reconnectOrg === 'true';
  const headers = new HttpHeaders({'el-team-id': String(teamId), 'el-team': String(teamIndex)});
  const returnBackToReferenceModels = (
    queryParams:
      | {
          orgId: string;
          message: string;
        }
      | object = {}
  ) => router.navigate([`/t/${+teamIndex}/referencemodels-external`], {queryParams});

  const openSfConnectionIfoDialog = (payload: OrgGeneralInfoPayload) => {
    return dialog
      .open(SfConnectionInfoDialogComponent, {
        width: DialogWidth.MD,
        disableClose: !isReconnect,
        data: {
          ...payload,
          color: ThemePalette.WARN
        }
      })
      .afterClosed();
  };

  const catchErrorHandler = () => {
    return catchError(error => {
      return dialog
        .open(ConfirmModalDialogComponent, {
          width: DialogWidth.SM,
          data: {
            modalTitle: 'GENERAL.ERROR_TITLE',
            content: get(error, 'error', 'Unhandled error'),
            confirmText: 'SF_CONNECTIONS.INDEX_MODAL_CONFIRM'
          }
        })
        .afterClosed()
        .pipe(
          tap(() => returnBackToReferenceModels()),
          finalize(() => sessionService.setLoading(false))
        );
    });
  };

  const {uiTime, backEndTime} = getRandomScheduleTime();
  const generalOrgInfoUrl = getGeneralOrgInfoUrl(teamId, implementationId);

  sessionService.setLoading();

  return http.post<GeneralOrgInfoResponse>(generalOrgInfoUrl, {code, isSandbox}, {headers}).pipe(
    mergeMap(({result, isSuccess}) => {
      const orgGeneralInfo = {uiTime, ...parsedSfQueryParams, ...result, isSuccess};
      const isConnectOrg = isSuccess && !result.alreadyConnected && !isReconnect;

      const connectOrg = () => {
        const connectOrgPayload = {
          hour: backEndTime,
          implementationId,
          username: result.username,
          orgId: result.orgId,
          teamId
        };
        const orgType = isSandbox === 'false' ? 'connectProduction' : 'connectSandbox';
        const connectOrgUrl = getConnectOrgUrl(teamId, implementationId, orgType);

        return http.put<ConnectOrgResponse>(connectOrgUrl, connectOrgPayload, {headers}).pipe(
          tap(connectOrgResponse => {
            const startSync = (refModelId: string) => {
              const url = `${ConfigService.environment.API_ENDPOINT}/refmodel/${type}/structure/sync/${refModelId}`;

              return http.post(url, null, {headers}).pipe(handleError()).subscribe();
            };

            const refModelId = get(connectOrgResponse, 'refModelId');

            if (!refModelId) {
              return;
            }

            startSync(refModelId);
          }),
          map(connectOrgResponse => ({...orgGeneralInfo, ...connectOrgResponse})),
          handleError(),
          catchErrorHandler()
        );
      };

      return isConnectOrg ? connectOrg() : of(orgGeneralInfo);
    }),
    switchMap((orgGeneralInfo: OrgGeneralInfoPayload) => {
      if (!(orgGeneralInfo.isSuccess || get(orgGeneralInfo, 'alreadyConnected'))) {
        return returnBackToReferenceModels();
      }

      return openSfConnectionIfoDialog(orgGeneralInfo).pipe(
        switchMap(() => {
          return returnBackToReferenceModels(
            orgGeneralInfo?.alreadyConnected ? {} : {orgId: orgGeneralInfo.orgId}
          );
        })
      );
    }),
    handleError(),
    finalize(() => sessionService.setLoading(false)),
    catchErrorHandler()
  );
};

const getGeneralOrgInfoUrl = (teamId: string, implementationId: string) => {
  const domain = `${ConfigService.environment.API_ENDPOINT}/organizations/${teamId}`;
  const endPoint = `implementations/${implementationId}/generalSfOrgInfo`;

  return `${domain}/${endPoint}`;
};

const getConnectOrgUrl = (teamId: string, implementationId: string, orgType: string) => {
  const domain = `${ConfigService.environment.API_ENDPOINT}/organizations/${teamId}`;
  const endPoint = `implementations/${implementationId}/${orgType}`;

  return `${domain}/${endPoint}`;
};

const getRandomNumber = () => {
  const startDate = setHours(new Date(), 21);
  const endDate = setHours(new Date(), 7);
  const randomHoursForScheduling = [];

  let currentHour = startDate;

  while (getHours(currentHour) !== getHours(endDate)) {
    randomHoursForScheduling.push(getHours(currentHour));
    currentHour = addHours(currentHour, 1);
  }

  // Handle the case where startDate is after endDate (crosses midnight)
  if (getHours(currentHour) === getHours(endDate)) {
    randomHoursForScheduling.push(getHours(currentHour));
  }

  const num = Math.floor(Math.random() * Math.floor(randomHoursForScheduling.length - 1));

  return randomHoursForScheduling[num];
};

/** This method will get random time between 9pm(21:00) and 7am(7:00) hour and return object
 * {
 *   uiTime: time in users time zone (simple string: '1:00') - need to show it in dialog
 *   backEndTime: moment time in utc - will send on backend for save in DB
 * } */
const getRandomScheduleTime = () => {
  const randomHour = getRandomNumber();
  const uiTime = `${randomHour}:00`;
  const value = uiTime.split(':')[0];
  const backEndTime = setSeconds(setMinutes(setHours(new Date(), +value), 0), 0);

  return {backEndTime, uiTime};
};

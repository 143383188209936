import {SfCallbackParams} from '../models/salesforce-orgs.interface';

export function sfCallbackParser(queryParams: SfCallbackParams) {
  const code = queryParams['code'];
  const stateParams = queryParams['state'].split(',');
  const paramsForParse = [
    'teamIndex',
    'teamId',
    'connectOrg',
    'implementationId',
    'isSandbox',
    'orgId',
    'type',
    'reconnectOrg'
  ] as const;
  const parsedValues = {code} as SfCallbackParams;

  paramsForParse.forEach(queryParameter => {
    const paramString = stateParams.find((param: string) => param.startsWith(queryParameter));

    if (paramString) {
      parsedValues[queryParameter] = paramString.replace(`${queryParameter}:`, '');
    }
  });

  return parsedValues;
}

import {inject} from '@angular/core';
import {Auth, signInWithCustomToken} from '@angular/fire/auth';
import {CanMatchFn, Router} from '@angular/router';
import {SessionService} from '@q9elements/ui-kit/common';
import {map} from 'rxjs';

import {USER_STATE} from '../models/user-state.enum';

export const SessionGuard: CanMatchFn = (route, segments) => {
  const sessionService = inject(SessionService);
  const [root, index] = segments;
  const teamIndex = index && Number.parseInt(index?.path);

  if (sessionService.user() && sessionService.teamIndex() === teamIndex) {
    return true;
  }

  const hasTeamIndex = Number.isInteger(+teamIndex);
  const isSpaceIndexRoute = root?.path === 't';

  if (isSpaceIndexRoute && hasTeamIndex) {
    sessionService.user.set({
      userInTeam: {
        teamIndex: +index.path
      }
    } as never);
  }

  const router = inject(Router);
  const auth = inject(Auth);

  return sessionService.startSession().pipe(
    map(() => {
      const user = sessionService.user()!;

      if (user.state !== USER_STATE.ACTIVE) {
        return router.createUrlTree(['']);
      }

      signInWithCustomToken(auth, user.firebaseToken!);

      if (isSpaceIndexRoute && !hasTeamIndex) {
        return router.createUrlTree(['t', sessionService.teamIndex()]);
      }

      return true;
    })
  );
};

import {inject} from '@angular/core';
import {CanMatchFn} from '@angular/router';
import {SpaceSettingsService} from '@q9elements/ui-kit/common';

import {NavigationService} from '../services/navigation.service';

export const ChangesGuard: CanMatchFn = route => {
  const spaceSettingsService = inject(SpaceSettingsService);

  if (route.path !== 'changes') {
    spaceSettingsService.updateSettingsByKey('changesPath', route.path!);

    return true;
  }

  const module = spaceSettingsService.getSettingsByKey('changesPath', 'requirements') as string;
  const navigationService = inject(NavigationService);

  return navigationService.navigateToModule(module);
};

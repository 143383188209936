<div mat-dialog-title>
  <span>
    {{
      (!data.alreadyConnected
        ? 'SF_CONNECTIONS.ORG_CONNECTED_TITLE'
        : 'SF_CONNECTIONS.ORG_CONNECTION_FAILED'
      ) | translate
    }}
  </span>
  <q9-dialog-close-button *ngIf="data.isReconnect"></q9-dialog-close-button>
</div>

<mat-dialog-content>
  <q9-section>
    <div content>
      <ng-container *ngIf="!data.alreadyConnected">
        <div
          [innerHTML]="
            'SF_CONNECTIONS.ORG_CONNECTED_MSG_1'
              | translate: {orgId: data.orgId, username: data.username}
          "></div>

        <div class="msg-paragraph">{{ 'SF_CONNECTIONS.ORG_CONNECTED_MSG_2' | translate }}</div>

        <div
          [innerHTML]="
            'SF_CONNECTIONS.ORG_CONNECTED_MSG_3' | translate: {timeString: data.uiTime}
          "></div>
      </ng-container>

      <ng-container *ngIf="data.alreadyConnected">
        <div
          [innerHTML]="
            'SF_CONNECTIONS.CONNECT_CONNECTED_ORG_MESSAGE' | translate: {username: data.username}
          "></div>

        <div
          [innerHTML]="
            'SF_CONNECTIONS.CONNECT_CONNECTED_ORG_MESSAGE_2' | translate: {username: data.username}
          "></div>
      </ng-container>
    </div>
  </q9-section>
</mat-dialog-content>

<mat-dialog-actions>
  <q9-secondary-button [color]="data.color" mat-dialog-close *ngIf="data.isReconnect">
    {{ 'REFERENCE_MODELS.CREATE_CANCEL' | translate }}
  </q9-secondary-button>
  <q9-primary-button mat-dialog-close [color]="data.color">
    {{ 'GENERAL.OK' | translate }}
  </q9-primary-button>
</mat-dialog-actions>
